/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, OnInit, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  ReactiveFormsModule,
} from "@angular/forms";
import { IFormArrayItem } from "./IFormArrayItem";
import { CollectionDefinition } from "../../crud/model/crud.state";
import { NgForOf, NgIf } from "@angular/common";

@Component({
  selector: "app-fill-form",
  templateUrl: "./fill-form.component.html",
  styleUrls: ["./fill-form.component.scss"],
  imports: [ReactiveFormsModule, NgForOf, NgIf],
  standalone: true,
})
export class FillFormComponent {
  public onTouched: () => void = () => {};
  public items?: FormArray;

  @Input() public submitAction?: (formData: any) => void;

  @Input() form?: FormGroup;

  _collectionDefinition: IFormArrayItem[] = [];
  _elementForEdit: any;
  @Input() set collectionDefinition(
    collectionDefinition: CollectionDefinition | null | undefined,
  ) {
    // TODO: Use Signals
    if (collectionDefinition) {
      this._collectionDefinition =
        collectionDefinition.collectionDefinitionProperties;
      this.fillForEdit();
    }
  }
  @Input() set elementForEdit(elementForEdit: any) {
    if (elementForEdit) {
      this._elementForEdit = elementForEdit;
      this.fillForEdit();
    }
  }

  fillForEdit() {
    if (this._collectionDefinition) {
      this.form = new UntypedFormGroup({ fields: new UntypedFormArray([]) });
      this._collectionDefinition.map((arrayItem: IFormArrayItem) =>
        this.addItem(arrayItem),
      );
      if (this._elementForEdit) {
        this._collectionDefinition.forEach(({ name }: IFormArrayItem) => {
          if (this._elementForEdit[name]) {
            let values = this.formControl.getRawValue();
            let index = values.findIndex((element) => {
              return Object.keys(element).includes(name);
            });
            this.formControl
              .at(index)
              .patchValue({ [name]: this._elementForEdit[name] });
          }
        });
      }
    }
  }

  public addItem(field: IFormArrayItem): void {
    this.items = this.formControl;
    this.items.push(this.createItem(field.name));
  }

  public createItem(field: string): FormGroup {
    return new UntypedFormGroup({
      [field]: new FormControl(null, [Validators.required]),
    });
  }

  public get formControl() {
    return (this.form?.get("fields") ?? new FormArray<any>([])) as FormArray;
  }

  public submit() {
    if (this.submitAction) {
      this.submitAction(
        this.formControl.getRawValue().reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            ...currentValue,
          }),
          {},
        ),
      );
    }
  }
}
