/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CollectionDefinition } from "./model/crud.state";
import { environment } from "../../../environments/environment";

export class CrudBaseService<T> {
  constructor(
    protected httpClient: HttpClient,
    private collectionName: string,
  ) {}

  getAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(
      `${environment.backendURL}/${this.collectionName}`,
    );
  }

  insert(element: T): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backendURL}/${this.collectionName}`,
      element,
    );
  }

  getById(id: string): Observable<T> {
    return this.httpClient.get<T>(
      `${environment.backendURL}/${this.collectionName}/${id}`,
    );
  }

  replace(element: T): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.backendURL}/${this.collectionName}`,
      element,
    );
  }

  getCollectionDefinition(): Observable<CollectionDefinition> {
    return this.httpClient.get<CollectionDefinition>(
      `${environment.backendURL}/${this.collectionName}/definition`,
    );
  }

  find(filter: { [key: string]: string } = {}): Observable<T[]> {
    let params = Object.entries(filter)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return this.httpClient.get<T[]>(
      `${environment.backendURL}/${this.collectionName}/find?${params}`,
      filter,
    );
  }
}
