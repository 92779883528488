/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CollectionDefinition } from "../../shared-components/crud/model/crud.state";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SystemService {
  constructor(protected httpClient: HttpClient) {}

  getClassDefinition(assemblyName: string): Observable<CollectionDefinition> {
    return this.httpClient.get<CollectionDefinition>(
      `${environment.backendURL}/system/GetClassDefinition?AssemblyName=${assemblyName}`,
    );
  }
}
