/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import {merge, mergeMap, Subject} from "rxjs";
import {Injectable, Injector, OnDestroy} from "@angular/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Actions, ofType} from "@ngrx/effects";
import {ActivatedRoute} from "@angular/router";
import {CrudStoreService} from "../services/crud.store.service";
import {CrudActions} from "./crud.actions";
import {Store} from "@ngrx/store";
import {HttpClient} from "@angular/common/http";
import {SystemService} from "../../../shared-services/system/system.service";
import {CrudBaseService} from "../crud.base.service";
import {concatLatestFrom} from "@ngrx/operators";
import {map, tap} from "rxjs/operators";
import {CollectionDefinition, JoinCollection} from "../model/crud.state";
import {AddEditPageComponent} from "../modals/add-edit/page/add-edit.page.component";


@Injectable()
export class CrudEffects implements OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();
  modalRef?: NgbModalRef;
  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private injector: Injector,
    private crudStoreService: CrudStoreService,
    private crudActions: CrudActions,
    // private effectsRootModule: EffectsRootModule,
    private store: Store,
    protected httpClient: HttpClient,
    private modalService: NgbModal,
    private systemService: SystemService,
    private activatedRoute: ActivatedRoute,
  ) {}
  getService(collectionName: string): CrudBaseService<any> {
    return new CrudBaseService<any>(this.httpClient, collectionName);
  }
  init(): void {
    let getAll$ = this.actions$.pipe(
      ofType(this.crudActions.getAll()),
      concatLatestFrom(() => [this.crudStoreService.selectCollectionName()]),
      mergeMap(([, collectionName]: [any, string]) => {
        return this.getService(collectionName).getAll();
      }),
      map((elements: any[]) => this.crudActions.setAll()({ elements })),
    );

    let insert$ = this.actions$.pipe(
      ofType(this.crudActions.insert()),
      concatLatestFrom(() => [this.crudStoreService.selectCollectionName()]),
      mergeMap(([{ element }, collectionName]: [{ element: any }, string]) =>
        this.getService(collectionName).insert(element),
      ),
      tap(() => this.crudStoreService.close()),
      map(() => this.crudActions.getAll()()),
    );

    let replace$ = this.actions$.pipe(
      ofType(this.crudActions.replace()),
      concatLatestFrom(() => [
        this.crudStoreService.selectCollectionName(),
        this.crudStoreService.selectElement(),
      ]),
      mergeMap(
        ([{ element }, collectionName, originalElement]: [
          { element: any },
          string,
          any,
        ]) =>
          this.getService(collectionName).replace({
            ...originalElement,
            ...element,
          }),
      ),
      tap(() => this.crudStoreService.close()),
      map(() => this.crudActions.getAll()()),
    );

    let getCollectionDefinition$ = this.actions$.pipe(
      ofType(this.crudActions.getCollectionDefinition()),
      concatLatestFrom(() => [this.crudStoreService.selectCollectionName()]),
      mergeMap(([, collectionName]: [any, string]) => {
        return this.getService(collectionName).getCollectionDefinition();
      }),
      tap((collectionDefinition: CollectionDefinition) => {
        collectionDefinition.joinCollections.forEach(
          (collectionDefinition: JoinCollection) => {
            this.crudStoreService.getJoinCollectionDefinition(
              collectionDefinition,
            );
          },
        );
      }),
      map((collectionDefinition: CollectionDefinition) =>
        this.crudActions.setCollectionDefinition()({ collectionDefinition }),
      ),
    );

    let getCollectionData$ = this.actions$.pipe(
      ofType(this.crudActions.getJoinCollectionData()),
      mergeMap(
        ({
          joinCollection,
          element,
        }: {
          joinCollection: JoinCollection;
          element: any;
        }) =>
          this.getService(joinCollection.entity)
            .find({
              [joinCollection.foreignField]: element[joinCollection.localField],
            })
            .pipe(
              map((collectionData) => ({ collectionData, joinCollection })),
            ),
      ),
      map(
        ({
          collectionData,
          joinCollection,
        }: {
          collectionData: any[];
          joinCollection: JoinCollection;
        }) =>
          this.crudActions.setJoinCollectionData()({
            collectionData,
            joinCollection,
          }),
      ),
    );

    let getById$ = this.actions$.pipe(
      ofType(this.crudActions.getById()),
      concatLatestFrom(() => [this.crudStoreService.selectCollectionName()]),
      mergeMap(
        ([{ elementId }, collectionName]: [{ elementId: string }, string]) =>
          this.getService(collectionName).getById(elementId),
      ),
      map((element: any) => this.crudActions.setElement()({ element })),
    );

    let getJoinCollectionDefinition$ = this.actions$.pipe(
      ofType(this.crudActions.getJoinCollectionDefinition()),
      mergeMap(({ joinCollection }: { joinCollection: JoinCollection }) =>
        this.systemService.getClassDefinition(joinCollection.name).pipe(
          map((collectionDefinition: CollectionDefinition) => ({
            collectionDefinition,
            joinCollection,
          })),
        ),
      ),
      map(
        ({
          collectionDefinition,
          joinCollection,
        }: {
          collectionDefinition: CollectionDefinition;
          joinCollection: JoinCollection;
        }) =>
          this.crudActions.setJoinCollectionDefinition()({
            collectionDefinition,
            joinCollection,
          }),
      ),
    );

    let openEditModal$ = this.actions$.pipe(
      ofType(this.crudActions.open()),
      tap(({ elementId }: { elementId: string }) => {
        this.crudStoreService.setElement(null);
        if (this.modalRef) {
          this.modalRef.close();
          delete this.modalRef;
        }
        this.modalRef = this.modalService.open(AddEditPageComponent);
        if (elementId) {
          this.modalRef.componentInstance.edit(elementId);
        }
      }),
      map(() => this.crudActions.success()()),
    );

    let closeEditModal$ = this.actions$.pipe(
      ofType(this.crudActions.close()),
      tap(() => {
        if (this.modalRef) {
          this.modalRef.close();
          delete this.modalRef;
        }
      }),
      map(() => this.crudActions.success()()),
    );

    let effectsArray = [
      getAll$,
      insert$,
      replace$,
      getCollectionDefinition$,
      getById$,
      getJoinCollectionDefinition$,
      getCollectionData$,
      // Add edit modal
      openEditModal$,
      closeEditModal$,
    ];
    merge(...effectsArray).subscribe((action: any) => {
      this.store.dispatch(action);
    });

    this.activatedRoute.queryParams.subscribe((parans) =>
      console.log("parans", parans),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
