/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  CrudState,
  JoinCollection,
  JoinCollectionWithDefinition,
} from "../model/crud.state";
import { Injectable } from "@angular/core";

@Injectable()
export class CrudSelectors {
  featureName: string = "";
  setFeatureName(featureName: string) {
    this.featureName = featureName;
  }
  selectCrud = () => createFeatureSelector<CrudState>(this.featureName);
  selectList = () =>
    createSelector(this.selectCrud(), (state: CrudState) => state.list);
  selectCollectionDefinition = () =>
    createSelector(
      this.selectCrud(),
      (state: CrudState) => state.collectionDefinition,
    );

  selectCollectionName = () =>
    createSelector(
      this.selectCrud(),
      (state: CrudState) => state.collectionName,
    );

  selectElement = () =>
    createSelector(this.selectCrud(), (state: CrudState) => state.element);

  selectAllJoinCollections = () =>
    createSelector(
      this.selectCrud(),
      (state: CrudState) => state.joinCollections,
    );

  selectJoinCollectionDefinition = (joinCollection: JoinCollection) =>
    createSelector(
      this.selectAllJoinCollections(),
      (state: { [key: string]: JoinCollectionWithDefinition }) =>
        state[joinCollection.name],
    );
}
