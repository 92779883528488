<ng-container *ngIf="form">
  <ng-container [formGroup]="form">
    <div class="container fill-form" *ngIf="_collectionDefinition">
      <div class="row" formArrayName="fields">
        <div
          *ngFor="let arrayItem of _collectionDefinition; index as i"
          class="col-md-12"
        >
          <div formGroupName="{{ i }}" class="fill-form-content">
            <label [for]="arrayItem.name" *ngIf="arrayItem.name">
              {{ arrayItem.name }}
            </label>
            <input
              [id]="arrayItem.name"
              [placeholder]="arrayItem.name"
              formControlName="{{ arrayItem.name }}"
              [type]="arrayItem.type ? arrayItem.type : 'text'"
              class="ptb-input fill-form-content-input"
            />
          </div>
        </div>
        <button (click)="submit()">Submit</button>
      </div>
    </div>
  </ng-container>
</ng-container>
