/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { Injectable, Injector } from "@angular/core";
import * as actions from "./../store/crud.actions";
import * as selectors from "./../store/crud.selectors";
import { ActivatedRoute } from "@angular/router";
import { ReducerManager, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { CrudActions } from "./../store/crud.actions";
import { CrudSelectors } from "./../store/crud.selectors";
import {
  CollectionDefinition,
  JoinCollection,
  JoinCollectionWithDefinition,
} from "../model/crud.state";
import { CrudReducers } from "../store/crud.reducers";
import { tap } from "rxjs/operators";

@Injectable()
export class CrudStoreService {
  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private store: Store,
    private reducerManager: ReducerManager,
    private crudActions: CrudActions,
    private crudReducers: CrudReducers,
    private crudSelectors: CrudSelectors,
  ) {}

  public setCollectionName(collectionName: string): void {
    this.reducerManager.addReducer(
      collectionName,
      this.crudReducers.crudReducer(),
    );
    this.store.dispatch(
      this.crudActions.setCollectionName()({ collectionName }),
    );
  }

  public getAll(): void {
    this.store.dispatch(this.crudActions.getAll()());
  }

  public getJoinCollectionDefinition(joinCollection: JoinCollection): void {
    this.store.dispatch(
      this.crudActions.getJoinCollectionDefinition()({ joinCollection }),
    );
  }

  public getJoinCollectionData(
    joinCollection: JoinCollection,
    element: any,
  ): void {
    this.store.dispatch(
      this.crudActions.getJoinCollectionData()({ joinCollection, element }),
    );
  }

  public getCollectionDefinition(): void {
    this.store.dispatch(this.crudActions.getCollectionDefinition()());
  }

  public getById(elementId: string): void {
    this.store.dispatch(this.crudActions.getById()({ elementId }));
  }

  public insert(element: any[]): void {
    this.store.dispatch(this.crudActions.insert()({ element }));
  }

  public replace(element: any[]): void {
    this.store.dispatch(this.crudActions.replace()({ element }));
  }
  public setElement(element?: any[] | null): void {
    this.store.dispatch(this.crudActions.setElement()({ element }));
  }
  public selectList(): Observable<any[]> {
    return this.store.select(this.crudSelectors.selectList());
  }
  public selectCollectionDefinition(): Observable<CollectionDefinition> {
    return this.store.select(this.crudSelectors.selectCollectionDefinition());
  }
  public selectCollectionName(): Observable<string> {
    return this.store.select(this.crudSelectors.selectCollectionName());
  }

  public selectJoinCollectionDefinition(
    joinCollection: JoinCollection,
  ): Observable<JoinCollectionWithDefinition> {
    return this.store.select(
      this.crudSelectors.selectJoinCollectionDefinition(joinCollection),
    );
  }

  public selectElement(): Observable<any> {
    return this.store.select(this.crudSelectors.selectElement());
  }

  //Edit modal
  open(elementId: string = "") {
    this.store.dispatch(this.crudActions.open()({ elementId }));
  }

  close() {
    this.store.dispatch(this.crudActions.close()());
  }
}
