/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, OnInit } from "@angular/core";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { IFormArrayItem } from "../../../../forms/fill-form/IFormArrayItem";
import { Observable } from "rxjs";
import { CrudStoreService } from "../../../services/crud.store.service";
import { ActivatedRoute } from "@angular/router";
import { CollectionDefinition } from "../../../model/crud.state";
import { AddEditComponent } from "../component/add-edit.component";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-add-edit.private-page",
  templateUrl: "./add-edit.page.component.html",
  styleUrls: ["./add-edit.page.component.scss"],
  imports: [AddEditComponent, AsyncPipe],
  standalone: true,
})
export class AddEditPageComponent {
  form?: FormGroup;
  tenantFields: IFormArrayItem[] = [];
  isEdit: boolean = false;

  selectCollectionDefinition$: Observable<CollectionDefinition>;
  selectElementForEdit$: Observable<any>;

  constructor(private crudStoreService: CrudStoreService) {
    this.selectCollectionDefinition$ =
      this.crudStoreService.selectCollectionDefinition();
    this.selectElementForEdit$ = this.crudStoreService.selectElement();
  }

  // ngOnInit(): void {
  //   /*    this.form = new UntypedFormGroup({});
  //   this.form.addControl("fields", new UntypedFormArray([]));
  //
  //   this.tenantFields = [
  //     {
  //       fieldName: "name",
  //       id: "name",
  //       title: "Ingrese el nombre",
  //       columnSize: 6,
  //       value: null,
  //       label: "Nombre",
  //       type: "string",
  //     },
  //     {
  //       fieldName: "domain",
  //       id: "domain",
  //       title: "Ingrese el dominio",
  //       columnSize: 6,
  //       value: null,
  //       label: "Dominio",
  //       type: "string",
  //     },
  //   ];*/
  // }

  edit(elementId: string) {
    this.crudStoreService.getById(elementId);
  }
}
