/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createAction, props } from "@ngrx/store";
import { CollectionDefinition, JoinCollection } from "../model/crud.state";
import { Injectable } from "@angular/core";

@Injectable()
export class CrudActions {
  prefix = `[CRUD]`;
  setCollectionName = () =>
    createAction(
      `${this.prefix} SET_COLLECTION_NAME`,
      props<{ collectionName: string }>(),
    );
  getAll = () => createAction(`${this.prefix} GET_ALL`);
  setAll = () =>
    createAction(`${this.prefix} SET_ALL`, props<{ elements: any[] }>());
  clear = () => createAction(`${this.prefix} CLEAR`);
  insert = () =>
    createAction(`${this.prefix} INSERT`, props<{ element: any }>());
  replace = () =>
    createAction(`${this.prefix} REPLACE`, props<{ element: any }>());

  getById = () =>
    createAction(`${this.prefix} GET_BY_ID`, props<{ elementId: string }>());
  setElement = () =>
    createAction(`${this.prefix} SET_ELEMENT`, props<{ element: any }>());
  getCollectionDefinition = () =>
    createAction(`${this.prefix} GET_COLLECTION_DEFINITION`);

  setCollectionDefinition = () =>
    createAction(
      `${this.prefix} SET_COLLECTION_DEFINITION`,
      props<{ collectionDefinition: CollectionDefinition }>(),
    );

  getJoinCollectionDefinition = () =>
    createAction(
      `${this.prefix} GET_JOIN_COLLECTION_DEFINITION`,
      props<{ joinCollection: JoinCollection }>(),
    );

  setJoinCollectionDefinition = () =>
    createAction(
      `${this.prefix} SET_JOIN_COLLECTION_DEFINITION`,
      props<{
        joinCollection: JoinCollection;
        collectionDefinition: CollectionDefinition;
      }>(),
    );

  getJoinCollectionData = () =>
    createAction(
      `${this.prefix} GET_JOIN_COLLECTION_DATA`,
      props<{ joinCollection: JoinCollection; element: any }>(),
    );

  setJoinCollectionData = () =>
    createAction(
      `${this.prefix} SET_JOIN_COLLECTION_DATA`,
      props<{
        joinCollection: JoinCollection;
        collectionData: any[];
      }>(),
    );

  setPrefix(prefix: string) {
    this.prefix = prefix;
  }

  // EDIT Modal

  open = () =>
    createAction(
      `${this.prefix}[ADD_EDIT_MODAL] OPEN`,
      props<{ elementId: string }>(),
    );
  close = () => createAction(`${this.prefix}[ADD_EDIT_MODAL] CLOSE`);
  success = () => createAction(`${this.prefix}[ADD_EDIT_MODAL] SUCCESS`);
}
