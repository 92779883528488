/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/
import { Component, Input, OnInit } from "@angular/core";
import { CrudStoreService } from "../../../services/crud.store.service";
import { CollectionDefinition } from "../../../model/crud.state";
import { FillFormComponent } from "../../../../forms/fill-form/fill-form.component";

@Component({
  selector: "app-add-edit",
  templateUrl: "./add-edit.component.html",
  styleUrls: ["./add-edit.component.scss"],
  imports: [FillFormComponent],
  standalone: true,
})
export class AddEditComponent {
  constructor(private crudStoreService: CrudStoreService) {}

  @Input() collectionDefinition: CollectionDefinition | null | undefined; // TODO: Use Signals
  @Input() elementForEdit?: any;

  submit = (element: any) => {
    if (this.elementForEdit) {
      this.crudStoreService.replace(element);
    } else {
      this.crudStoreService.insert(element);
    }
  };
}
