/*************************
 *  UR TRAVEL AND SOFTWARE SAS 2018 - 2024
 *************************/

import { createReducer, on } from "@ngrx/store";
import * as actions from "./crud.actions";
import {
  CollectionDefinition,
  CollectionDefinitionProperty,
  CrudState,
  JoinCollection,
} from "../model/crud.state";
import { clone, setWith } from "lodash";
import { CrudActions } from "./crud.actions";
import { Injectable } from "@angular/core";

@Injectable()
export class CrudReducers {
  constructor(private crudActions: CrudActions) {}

  initialState: CrudState = {
    list: [],
    collectionDefinition: {
      collectionDefinitionProperties: [],
      joinCollections: [],
    },
    collectionName: "",
    element: null,
    joinCollections: {},
  };

  crudReducer = () =>
    createReducer(
      this.initialState,
      on(
        this.crudActions.setAll(),
        (state: CrudState, { elements }: { elements: any[] }) =>
          setWith(clone(state), "list", elements, clone),
      ),
      on(
        this.crudActions.setElement(),
        (state: CrudState, { element }: { element: any[] }) =>
          setWith(clone(state), "element", element, clone),
      ),
      on(
        this.crudActions.setCollectionName(),
        (state: CrudState, { collectionName }: { collectionName: string }) =>
          setWith(clone(state), "collectionName", collectionName, clone),
      ),
      on(
        this.crudActions.setCollectionDefinition(),
        (
          state: CrudState,
          {
            collectionDefinition,
          }: { collectionDefinition: CollectionDefinition },
        ) =>
          setWith(
            clone(state),
            "collectionDefinition",
            collectionDefinition,
            clone,
          ),
      ),
      on(
        this.crudActions.setJoinCollectionDefinition(),
        (
          state: CrudState,
          {
            collectionDefinition,
            joinCollection,
          }: {
            joinCollection: JoinCollection;
            collectionDefinition: CollectionDefinition;
          },
        ) => {
          let newState: CrudState = clone(state);
          newState.joinCollections = {
            ...newState.joinCollections,
            [joinCollection.name]: {
              ...newState.joinCollections[joinCollection.name],
              joinCollection,
              collectionDefinition,
            },
          };
          return newState;
        },
      ),
      on(
        this.crudActions.setJoinCollectionData(),
        (
          state: CrudState,
          {
            collectionData,
            joinCollection,
          }: {
            joinCollection: JoinCollection;
            collectionData: any[];
          },
        ) => {
          let newState: CrudState = clone(state);
          newState.joinCollections = {
            ...newState.joinCollections,
            [joinCollection.name]: {
              ...newState.joinCollections[joinCollection.name],
              joinCollection,
              collectionData,
            },
          };
          return newState;
        },
      ),
      on(this.crudActions.clear(), () => clone(this.initialState)),
    );
}
